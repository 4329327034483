import React from 'react';
import { ThemeProvider } from './ThemeProvider';

interface ThemeWrapperProps {
  element: React.ReactElement;
}

export const ThemeWrapper: React.FC<ThemeWrapperProps> = ({
  element,
}: ThemeWrapperProps) => <ThemeProvider>{element}</ThemeProvider>;
