import { ThemeOption } from './ThemeOption';
import { getItem, removeItem, setItem } from '../../services/storage';

const key = 'theme';

export const getThemeFromStorage = (): ThemeOption | undefined =>
  getItem(key) as ThemeOption | undefined;

export const setThemeOnStorage = (value: ThemeOption): boolean =>
  setItem(key, value);

export const removeThemeFromStorage = (): boolean => removeItem(key);
