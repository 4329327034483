import React, { useEffect, useMemo, useState } from 'react';
import { ThemeContext } from './ThemeContext';
import { defaultThemeOption } from './themeOptions';
import { ThemeOption } from './ThemeOption';
import {
  getThemeFromStorage,
  removeThemeFromStorage,
  setThemeOnStorage,
} from './themeStorage';

export interface ThemeProviderProps {
  children: React.ReactElement;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
}: ThemeProviderProps) => {
  const [theme, setTheme] = useState<ThemeOption>(defaultThemeOption);
  const [preferDarkMode, setPreferDarkMode] = useState<boolean>(false);

  const loadTheme = (): void => {
    const storageTheme = getThemeFromStorage();
    const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (storageTheme?.id === 'dark' || (!storageTheme && darkMode)) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    setPreferDarkMode(darkMode);
  };

  const changeTheme = (option: ThemeOption) => {
    if (option.id === 'light' || option.id === 'dark') {
      setThemeOnStorage(option);
    } else {
      removeThemeFromStorage();
    }

    setTheme(option);
  };

  const themeProviderValue = useMemo(
    () => ({ preferDarkMode, theme, changeTheme }),
    [preferDarkMode, theme, changeTheme]
  );

  useEffect(() => {
    const storageTheme = getThemeFromStorage();
    if (storageTheme) {
      setTheme(storageTheme);
    }
  }, []);

  useEffect(() => {
    const prefersColorScheme = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    prefersColorScheme.addEventListener('change', loadTheme);

    return () => prefersColorScheme.removeEventListener('change', loadTheme);
  }, []);

  useEffect(() => {
    loadTheme();
  }, [theme]);

  return (
    <ThemeContext.Provider value={themeProviderValue}>
      {children}
    </ThemeContext.Provider>
  );
};
