exports.components = {
  "component---src-components-post-post-template-tsx-content-file-path-content-articles-article-1-article-1-mdx": () => import("./../../../src/components/Post/PostTemplate.tsx?__contentFilePath=/home/runner/work/onesafe/onesafe/apps/website/content/articles/article1/article1.mdx" /* webpackChunkName: "component---src-components-post-post-template-tsx-content-file-path-content-articles-article-1-article-1-mdx" */),
  "component---src-components-post-post-template-tsx-content-file-path-content-articles-article-2-article-2-mdx": () => import("./../../../src/components/Post/PostTemplate.tsx?__contentFilePath=/home/runner/work/onesafe/onesafe/apps/website/content/articles/article2/article2.mdx" /* webpackChunkName: "component---src-components-post-post-template-tsx-content-file-path-content-articles-article-2-article-2-mdx" */),
  "component---src-components-post-post-template-tsx-content-file-path-content-articles-article-3-article-3-mdx": () => import("./../../../src/components/Post/PostTemplate.tsx?__contentFilePath=/home/runner/work/onesafe/onesafe/apps/website/content/articles/article3/article3.mdx" /* webpackChunkName: "component---src-components-post-post-template-tsx-content-file-path-content-articles-article-3-article-3-mdx" */),
  "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-1-guide-1-mdx": () => import("./../../../src/components/Post/PostTemplate.tsx?__contentFilePath=/home/runner/work/onesafe/onesafe/apps/website/content/guides/guide1/guide1.mdx" /* webpackChunkName: "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-1-guide-1-mdx" */),
  "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-2-guide-2-mdx": () => import("./../../../src/components/Post/PostTemplate.tsx?__contentFilePath=/home/runner/work/onesafe/onesafe/apps/website/content/guides/guide2/guide2.mdx" /* webpackChunkName: "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-2-guide-2-mdx" */),
  "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-4-guide-4-mdx": () => import("./../../../src/components/Post/PostTemplate.tsx?__contentFilePath=/home/runner/work/onesafe/onesafe/apps/website/content/guides/guide4/guide4.mdx" /* webpackChunkName: "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-4-guide-4-mdx" */),
  "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-5-guide-5-mdx": () => import("./../../../src/components/Post/PostTemplate.tsx?__contentFilePath=/home/runner/work/onesafe/onesafe/apps/website/content/guides/guide5/guide5.mdx" /* webpackChunkName: "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-5-guide-5-mdx" */),
  "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-6-guide-6-mdx": () => import("./../../../src/components/Post/PostTemplate.tsx?__contentFilePath=/home/runner/work/onesafe/onesafe/apps/website/content/guides/guide6/guide6.mdx" /* webpackChunkName: "component---src-components-post-post-template-tsx-content-file-path-content-guides-guide-6-guide-6-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artigos-tsx": () => import("./../../../src/pages/artigos.tsx" /* webpackChunkName: "component---src-pages-artigos-tsx" */),
  "component---src-pages-guias-tsx": () => import("./../../../src/pages/guias.tsx" /* webpackChunkName: "component---src-pages-guias-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sobre-tsx": () => import("./../../../src/pages/sobre.tsx" /* webpackChunkName: "component---src-pages-sobre-tsx" */)
}

