const getItem = (key: string): string | undefined => {
  try {
    const serializedValue: string | null = localStorage.getItem(key);
    if (serializedValue === null) {
      return undefined;
    }

    return JSON.parse(serializedValue);
  } catch (error) {
    return undefined;
  }
};

const setItem = (key: string, value: string | object): boolean => {
  try {
    const serializedValue: string = JSON.stringify(value);

    localStorage.setItem(key, serializedValue);

    return true;
  } catch (error) {
    return false;
  }
};

const removeItem = (key: string): boolean => {
  try {
    localStorage.removeItem(key);

    return true;
  } catch (error) {
    return false;
  }
};

export { getItem, setItem, removeItem };
