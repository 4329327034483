import { ThemeOption } from './ThemeOption';

export const themeOptions: ThemeOption[] = [
  { id: 'light', name: 'Claro', default: false },
  { id: 'dark', name: 'Escuro', default: false },
  { id: 'system', name: 'Sistema', default: true },
];

export const defaultThemeOption: ThemeOption = themeOptions.find(
  (option: ThemeOption) => option.default
) as ThemeOption;
