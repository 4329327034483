import { createContext } from 'react';
import { ThemeOption } from './ThemeOption';
import { defaultThemeOption } from './themeOptions';

export interface ThemeContextProps {
  preferDarkMode: boolean;
  theme: ThemeOption;
  changeTheme: (newTheme: ThemeOption) => void;
}

export const ThemeContext = createContext<ThemeContextProps>({
  preferDarkMode: false,
  theme: defaultThemeOption,
  changeTheme: () => null,
});
